import styled from "@emotion/styled";
import { css } from "@emotion/react";

/**
 * @context these values are hardcoded into student, let someone know if these need to be changed
 */
const SECONDARY_NAV_HEIGHT_MOBILE = 89;
const SECONDARY_NAV_HEIGHT = 44;

export const SecondaryOuter = styled.nav(
  ({ parent }) => css`
    display: none;
    position: relative;
    background: white;
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    min-width: 290px;
    border-bottom: 1px solid #d5d5d5;
    box-shadow: 0 5px 5px 0px rgba(0, 0, 0, 0.1);
    ${parent !== "noSecondary" && "display: block;"}
  `
);

export const SecondaryContainer = styled.div`
  max-width: 1536px;
  margin: 0 auto;
  min-height: ${SECONDARY_NAV_HEIGHT}px;
  padding: 0 0 0 16px;
`;

export const SecondaryWrapper = styled.div`
  display: none;
  &.show {
    display: flex;
    height: ${SECONDARY_NAV_HEIGHT_MOBILE}px;
    @media only screen and (min-width: 880px) {
      height: ${SECONDARY_NAV_HEIGHT}px;
    }
  }
  &.blank {
    height: 44px;
  }
`;

export const SecondaryNav = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  .show & {
    height: ${SECONDARY_NAV_HEIGHT_MOBILE}px;
    @media only screen and (min-width: 880px) {
      height: ${SECONDARY_NAV_HEIGHT}px;
      flex-wrap: nowrap;
    }
  }
  .blank & {
    height: 44px;
  }
`;

export const SecondaryLeft = styled.div`
  flex: 0 0 auto;
  order: 1;
  height: 50%;
  font-weight: 700;
  font-size: 1.1em;
  max-width: 300px;
  display: flex;
  margin-right: 10px;
  @media only screen and (min-width: 880px) {
    height: 43px;
    margin-bottom: 2px;
  }
  & a {
    padding: 0.5em 0;
    color: black;
    text-decoration: none;
  }
`;

export const VerticalCenter = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
`;

export const Tagline = styled.p`
  display: none;
  font-weight: 400;
  font-size: 0.7em;
  color: #838383;
  padding: 0 10px;
  margin: 0 0 0 10px;
  line-height: 1;
  border-left: 1px solid #838383;
  @media only screen and (min-width: 1100px) {
    display: block;
  }
`;

export const SecondaryCenter = styled.div`
  width: 100%;
  height: 50%;
  min-width: 0;
  overflow: hidden;
  order: 3;
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  justify-content: center;
  @media only screen and (min-width: 880px) {
    order: 2;
    height: 100%;
  }
`;

export const SecondaryRight = styled.div`
  margin-right: 50px;
  height: 50%;
  order: 2;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: no-wrap;
  justify-content: center;
  @media only screen and (min-width: 880px) {
    order: 3;
    height: 100%;
  }
  & > .search-icon {
    display: block;
    width: 44px;
    height: 44px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyBmb2N1c2FibGU9ImZhbHNlIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCBtZWV0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9ImN1cnJlbnRDb2xvciIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiB2aWV3Qm94PSIwIDAgMzIgMzIiIGFyaWEtaGlkZGVuPSJ0cnVlIj4KPHBhdGggZD0iTTI5LDI3LjU4NTlsLTcuNTUyMS03LjU1MjFhMTEuMDE3NywxMS4wMTc3LDAsMSwwLTEuNDE0MSwxLjQxNDFMMjcuNTg1OSwyOVpNNCwxM2E5LDksMCwxLDEsOSw5QTkuMDEsOS4wMSwwLDAsMSw0LDEzWiI+PC9wYXRoPgo8L3N2Zz4=");
    background-size: 24px;
    background-position: center;
    background-repeat: no-repeat;
  }
  & > .ambassadors-icon {
    display: block;
    width: 44px;
    height: 44px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgY2xpcC1wYXRoPSJ1cmwoI2EpIj4KPHBhdGggZD0iTTIxLjkxMyAyMC4xMDRjMS4zNS0xLjQ0OCAyLjA4Ni0zLjIzOSAyLjA4Ni01LjEwNCAwLTIuMzM1LTEuMTUzLTQuNDQ4LTMtNS45NDgtLjA0MiA1LjM0OC01LjA3MSA5LjY5Ny0xMS4yNDkgOS42OTdINS41N2MxLjYxNiAyLjY2NyA0LjkwMiA0LjUgOC42OCA0LjVoOC45OTlhLjc1Ljc1IDAgMCAwIC41My0xLjI4bC0xLjg2Ni0xLjg2NXoiIGZpbGw9IiMzQjlGRTYiLz48cGF0aCBkPSJNOS43NS43NTFDNC4zNzQuNzUxLjAwMSA0LjQ1LjAwMSA5LjAwMWMwIDEuODY1LjczNiAzLjY1IDIuMDg2IDUuMTA0TC4yMjIgMTUuOTdhLjc1Ljc1IDAgMCAwIC41MyAxLjI4SDkuNzVjNS4zNzYgMCA5Ljc1LTMuNjk4IDkuNzUtOC4yNSAwLTQuNTUtNC4zNzQtOC4yNDktOS43NS04LjI0OXoiIGZpbGw9IiM2OTMzRjciLz48cGF0aCBkPSJNMTQuMjUgNi43NWgtOWEuNzUyLjc1MiAwIDAgMC0uNzQ5Ljc1YzAgLjQxMy4zMzcuNzUuNzUuNzVoOC45OTljLjQxMiAwIC43NS0uMzM3Ljc1LS43NWEuNzUyLjc1MiAwIDAgMC0uNzUtLjc1em0tMyAzaC02YS43NTIuNzUyIDAgMCAwLS43NDkuNzVjMCAuNDEzLjMzNy43NS43NS43NWg2Yy40MTIgMCAuNzUtLjMzNy43NS0uNzVhLjc1Mi43NTIgMCAwIDAtLjc1LS43NXoiIGZpbGw9IiNGQUZBRkEiLz48L2c+PGRlZnM+PGNsaXBQYXRoIGlkPSJhIj48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMCAwaDI0djI0SDB6Ii8+PC9jbGlwUGF0aD48L2RlZnM+Cjwvc3ZnPg==");
    background-size: 24px;
    background-position: center;
    background-repeat: no-repeat;
  }
`;

export const SecondaryList = styled.ul`
  width: max-content;
  display: flex;
  position: static;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  @media only screen and (min-width: 880px) {
    padding: 0 30px;
    margin: 0 0 0 auto; // Right align
  }
`;

export const SecondaryItem = styled.li`
  list-style-type: none;
  margin: 0 10px;
  position: relative;
  flex: 0 0 auto;
  &:first-of-type {
    margin-left: 0;
  }
  & > a {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    align-content: center;
    color: #595959;
    text-decoration: none;
    padding: 0;
    width: 100%;
    height: 42px;
    border-bottom: 2px solid white;
    @media only screen and (min-width: 880px) {
      width: initial;
      align-items: center;
    }
    // see https://css-tricks.com/bold-on-hover-without-the-layout-shift/
    &::after {
      content: attr(data-text);
      height: 0;
      visibility: hidden;
      overflow: hidden;
      user-select: none;
      pointer-events: none;
      font-weight: 700;
      @media speech {
        display: none;
      }
    }
    &:hover,
    &:focus {
      color: black;
    }
    &.active {
      color: black;
      font-weight: 700;
      border-bottom: 2px solid black;
    }
  }
`;
