import React, { useContext } from "react";
import { MenuContext } from "../../contexts/MenuContext";
import NavLink from "../NavLink/NavLink";
import * as Styled from "./styled";
import { secondaryData } from "../../dataSecondary";
import ScrollControl from "../ScrollControl/ScrollControl";
import ConditionalWrapper from "../../utils/ConditionalWrapper";
import settings from "../../settings";

const SecondaryNav = (props) => {
  const context = useContext(MenuContext);
  const language = process.env.REACT_APP_LANGUAGE ?? "en";
  const d7_domain = settings.d7_domain;
  return (
    <Styled.SecondaryOuter
      data-position="secondary"
      parent={context.data.parent}
    >
      <Styled.SecondaryContainer>
        {context.data.parent === "blank" && (
          <Styled.SecondaryWrapper className="show blank">
            <Styled.SecondaryNav>
              <Styled.SecondaryLeft></Styled.SecondaryLeft>
              <Styled.SecondaryCenter></Styled.SecondaryCenter>
              <Styled.SecondaryRight>
                <NavLink
                  href={`${d7_domain}/search`}
                  className={"search-icon"}
                />
              </Styled.SecondaryRight>
            </Styled.SecondaryNav>
          </Styled.SecondaryWrapper>
        )}

        {secondaryData[language].map((data, key) => {
          const activeMenu = context.data.parent === data.parent;
          const activeItem = context.data.active ?? false;
          const taglineString = data.tagline ?? "";
          function createMarkup() {
            return { __html: taglineString };
          }

          return (
            <Styled.SecondaryWrapper
              key={key}
              className={context.data.parent === data.parent ? "show" : "hide"}
            >
              <Styled.SecondaryNav>
                <Styled.SecondaryLeft>
                  <Styled.VerticalCenter>
                    <NavLink href={data.href}>{data.label}</NavLink>
                  </Styled.VerticalCenter>

                  {data.tagline && (
                    <Styled.VerticalCenter>
                      <Styled.Tagline
                        dangerouslySetInnerHTML={createMarkup()}
                      ></Styled.Tagline>
                    </Styled.VerticalCenter>
                  )}
                </Styled.SecondaryLeft>
                <Styled.SecondaryCenter>
                  <ConditionalWrapper
                    condition={activeMenu}
                    wrapper={(children) => (
                      <ScrollControl activeItem={activeItem}>
                        {children}
                      </ScrollControl>
                    )}
                  >
                    <Styled.SecondaryList>
                      {data.menu.map((submenuitem, key) => {
                        return (
                          <Styled.SecondaryItem
                            key={key}
                            data-menu-id={submenuitem.id}
                          >
                            <NavLink
                              href={submenuitem.href}
                              dataText={submenuitem.label}
                              className={
                                context.data.active === submenuitem.id
                                  ? "active"
                                  : ""
                              }
                            >
                              {submenuitem.label}
                            </NavLink>
                          </Styled.SecondaryItem>
                        );
                      })}
                    </Styled.SecondaryList>
                  </ConditionalWrapper>
                </Styled.SecondaryCenter>
                <Styled.SecondaryRight>
                  {data.searchHref && (
                    <NavLink href={data.searchHref} className={"search-icon"} />
                  )}
                  {data.ambassadorsHref && (
                    <NavLink
                      href={data.ambassadorsHref}
                      className={"ambassadors-icon"}
                    />
                  )}
                </Styled.SecondaryRight>
              </Styled.SecondaryNav>
            </Styled.SecondaryWrapper>
          );
        })}
      </Styled.SecondaryContainer>
    </Styled.SecondaryOuter>
  );
};

export default SecondaryNav;
